import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { FaWhatsapp, FaCheck, FaTimes, FaPrint, FaMinus, FaPlus } from "react-icons/fa";
import TopHeader from "../Headers/TopHeader/TopHeader";
import BottomHeader from "../Headers/BottomHeader/BottomHeader";
import user from "../../assets/round.avif";
import Footer from "../Footer/Footer";
// import Modal from './Modal';
import axios from "axios";
import "./Order.css";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    axios
      .get("http://localhost:8081/Orders")
      .then((response) => {
        console.log(response.data); // Log the response data
        const formattedOrders = response.data.map((order) => ({
          ...order,
          Order_Date: formatDate(order.Order_Date), // Assuming Order_Date is a string date
        }));
        setOrders(formattedOrders); // Assuming response.data is an array of orders
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching orders:", error);
        setLoading(false);
      });
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  if (loading) {
    return (
      <div>
        <TopHeader />
        <BottomHeader />
        <div className="container">
          <div className="Orders myp-5">
            <h1 align="center" className="my-5">
              Customer Orders
            </h1>
            <p>Loading...</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  console.log("Orders:", orders); // Log orders state for debugging

  return (
    <div>
      <TopHeader />
      <BottomHeader />
      <div className="container">
        <div className="Orders myp-5">
          <h1 align="center" className="my-5">
            Customer Orders
          </h1>
          {orders.length === 0 ? (
            <p>No orders available</p>
          ) : (
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Order Date</th>
                  <th>Order No</th>
                  <th>Customer Code</th>
                  <th>Customer Name</th>
                  <th>Delivery Address</th>
                  <th>Phone Number</th>
                  <th>Net Total</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order.SRN}>
                    <td>{order.Order_Date}</td>
                    <td>{order.Order_No}</td>
                    <td>{order.Customer_Code}</td>
                    <td>{order.Customer_Name}</td>
                    <td>{order.Delivery_Address}</td>
                    <td>{order.Phone_Number}</td>
                    <td>{order.Net_Total}</td>
                    <td>
                      {" "}
                      <Link to="/Modal">View</Link>
                      {/* <Modal /> */}
                      {/* <Button variant="primary" onClick={handleShow}>
                        View
                      </Button> */}
                      {/* <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>
                            <div className="modal_header">
                              <div className="order_no">Order ID #1234</div>

                              <div className="btt">
                                <p>Mark Order As</p>
                                <div className="but">
                                  <button
                                    style={{
                                      backgroundColor: "#EF514E",
                                      color: "#fff",
                                    }}
                                  >
                                    <i class="fa-solid fa-x"></i>Cancelled
                                  </button>
                                  <button
                                    style={{
                                      backgroundColor: "#22CA6B",
                                      color: "#fff",
                                    }}
                                  >
                                    <i class="fa-solid fa-check"></i>Accept
                                  </button>
                                </div>
                                <div
                                  className="btttn d-flex "
                                  style={{
                                    justifyContent: "end",
                                    paddingTop: "10px",
                                  }}
                                >
                                  <button
                                    style={{
                                      backgroundColor: "#8CC248",
                                      color: "#fff",
                                    }}
                                  >
                                    Send Whatsaap to Customer{" "}
                                    <i class="fa-brands fa-whatsapp"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                        <div className="prfile_main my-5">
                              <div className="profile">
                                <div className="profile_img">
                                  <div className="user_img">
                                    <img src={user} alt="" />
                                  </div>
                                  <p
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    Ubaid Raza
                                  </p>

                                  <div className="tab">
                                    <h5>Customer Details</h5>
                                    <Table bordered variant="dark">
                                      <tbody>
                                        <tr>
                                          <td>Name</td>
                                          <td>Muhammad Ubaid</td>
                                        </tr>
                                        <tr>
                                          <td>Phone Numer</td>
                                          <td>03448182388</td>
                                        </tr>
                                        <tr>
                                          <td>Delivery Address</td>
                                          <td>Address no 15</td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                              <div className="order_detail">
                                <div className="buttons d-flex gap-2 align-items-center">
                                  <Button
                                    variant="primary"
                                    className="d-flex gap-2 align-items-center"
                                    style={{
                                      backgroundColor: "orange",
                                      color: "#fff",
                                      border: "1px solid orange",
                                    }}
                                    size="sm"
                                  >
                                    <i class="fa-solid fa-print"></i> Print
                                  </Button>{" "}
                                  <Button variant="primary" size="sm">
                                    Change Status
                                  </Button>{" "}
                                  <Button variant="primary" size="sm">
                                    Change Branch
                                  </Button>{" "}
                                </div>

                                <div className="table my-3">
                                  <h4>Order Details</h4>
                                  <Table bordered variant="dark ">
                                    <thead>
                                      <tr>
                                        <th>Order #</th>
                                        <th>Customer Ref #</th>
                                        <th>Order Status</th>
                                        <th>Order Date/Time</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>11d - 1379</td>
                                        <td>nfavsh</td>
                                        <td>
                                          <Button
                                            variant="primary"
                                            size="sm"
                                            className="success"
                                          >
                                            accepted
                                          </Button>{" "}
                                        </td>
                                        <td>15-7-24</td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>

                                 <div className="bracnh">
                                    <div className="bracnh_details">
                                    <p><b>Branch Name</b> <span>Art Stylo</span></p>
                                    <p><b>Payment Type</b> <span>Cash</span></p>
                                    <p><b>Delivery Date</b> <span>Jul,08,2024</span></p>
                                    <p><b>Delivery ASAP</b> <span>Yes</span></p>
                                    <p><b>Order Platfrom</b> <span>Art Stylo</span></p>
                                    </div>
                                    <div className="thai">
                                         <div className="thai_rice">
                                            <p><b>Wall Art</b> </p>
                                            <div className="per d-flex align-items-basaline gap-5">
                                            <p> 1 Wall Art <br /> <span style={{
                                                fontSize:"11px"
                                            }} >Rs. 500</span></p>
                                              <p>Rs. 500</p>
                                            </div>
                                            <div className="thai_rice">
                                            <p><b>Leaves Wall Art</b> </p>
                                            <div className="per d-flex align-items-basaline gap-5">
                                            <p> 1 Leaves Wall Art <br /> <span style={{
                                                fontSize:"11px"
                                            }} >Rs. 900</span></p>
                                              <p>Rs. 900</p>
                                            </div>
                                            </div>


                                          
                                         </div>
                                        
                                        
                                        </div>
                                        
                                 </div>
                                 <div className="div d-flex align-items-basaline gap-2 w-100">

                              
                                 <div className="per d-flex align-items-basaline gap-2 " style={
                                    {
                                        flexDirection:"column",
                                        width:"50%"
                                    }
                                 }>
                                             <div className="pera d-flex align-items-basaline gap-2" style={{
                                                backgroundColor:"#8CC14B",
                                                color:"#fff",
                                             padding:"10px 10px"
                                                
                                             }}>
                                                <div className="icon_minus" 
                                                style={{
                                                    display:"flex",
                                                    alignItems:"center",
                                                    flexDirection:"column",
                                                     whiteSpace:"nowrap"
                                                }} 
                                                >
                                                <button 
                                                style={{
                                                    border:"1px solid #fff",
                                                     borderRadius:"100%",
                                                
                                                }}
                                                >
                                                <i class="fa-solid fa-minus" 
                                                style={{
                                                    padding:"5px 10px",
                                                    color:"#000",
                                                    backgroundColor:"#fff",
                                                    borderRadius:"100%",
                                                       
                                                    
                                                }}
                                                ></i> </button><br /> <span style={{
                                                    marginTop:"-12px"
                                                }} >- 5 Mins</span>
                                                </div>
                                                <div className="delevery" 
                                                style={{
                                                     whiteSpace:"nowrap"
                                                }}
                                                >
                                                    <p>Delivery Time <br /> <span>
                                                        <b>07:23 PM</b></span></p>
                                                </div>
                                                <div className="icon_minus" 
                                                style={{
                                                    display:"flex",
                                                    alignItems:"center",
                                                    flexDirection:"column",
                                                    whiteSpace:"nowrap"
                                                }} 
                                                >
                                                <button 
                                                style={{
                                                    border:"1px solid #fff",
                                                     borderRadius:"100%",
                                                
                                                }}
                                                >
                                                <i class="fa-solid fa-minus" 
                                                style={{
                                                    padding:"5px 10px",
                                                    color:"#000",
                                                    backgroundColor:"#fff",
                                                    borderRadius:"100%",
                                                        whiteSpace:"nowrap"
                                                    
                                                }}
                                                ></i> </button><br /> <span style={{
                                                    marginTop:"-12px"
                                                }} >- 5 Mins</span>
                                                </div>
                                             </div>
                                            <div className="pera d-flex align-items-basaline gap-2" style={{
                                                backgroundColor:"#8CC14B",
                                                color:"#fff",
                                                padding:"10px 10px"
                                                
                                             }}>
                                                <div className="icon_minus" 
                                                style={{
                                                    display:"flex",
                                                    alignItems:"center",
                                                    flexDirection:"column",
                                                     whiteSpace:"nowrap"
                                                }} 
                                                >
                                                <button 
                                                style={{
                                                    border:"1px solid #fff",
                                                     borderRadius:"100%",
                                                
                                                }}
                                                >
                                                <i class="fa-solid fa-minus" 
                                                style={{
                                                    padding:"5px 10px",
                                                    color:"#000",
                                                    backgroundColor:"#fff",
                                                    borderRadius:"100%",
                                                       
                                                    
                                                }}
                                                ></i> </button><br /> <span style={{
                                                    marginTop:"-12px"
                                                }} >- 5 Mins</span>
                                                </div>
                                                <div className="delevery" 
                                                style={{
                                                     whiteSpace:"nowrap"
                                                }}
                                                >
                                                    <p>Delivery Time <br /> <span>
                                                        <b>07:23 PM</b></span></p>
                                                </div>
                                                <div className="icon_minus" 
                                                style={{
                                                    display:"flex",
                                                    alignItems:"center",
                                                    flexDirection:"column",
                                                    whiteSpace:"nowrap"
                                                }} 
                                                >
                                                <button 
                                                style={{
                                                    border:"1px solid #fff",
                                                     borderRadius:"100%",
                                                
                                                }}
                                                >
                                                <i class="fa-solid fa-minus" 
                                                style={{
                                                    padding:"5px 10px",
                                                    color:"#000",
                                                    backgroundColor:"#fff",
                                                    borderRadius:"100%",
                                                        whiteSpace:"nowrap"
                                                    
                                                }}
                                                ></i> </button><br /> <span style={{
                                                    marginTop:"-12px"
                                                }} >- 5 Mins</span>
                                                </div>
                                             </div>
                                            </div>
                                            <div className="tables w-100" 
                                             
                                             > 

                                         
                                           <Table bordered variant="dark" 
                                           style={{
                                               width:'100%'
                                             }}>
                                     <tbody>
                                       <tr>
                                         <td>Sub Total</td>
                                         <td>Rs 1450</td>
                                       </tr>
                                       <tr>
                                         <td>Tax 15.00%</td>
                                         <td>Rs 219</td>
                                       </tr>
                                       <tr>
                                         <td>Delivery Fee</td>
                                         <td>Rs 200</td>
                                       </tr>
                                       <tr>
                                         <td>Total</td>
                                         <td>Rs 1890</td>
                                       </tr>
                                     </tbody>
                                   </Table>
                                   </div>
                              </div>
                            </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                          <Button variant="primary" onClick={handleClose}>
                            Save Changes
                          </Button>
                        </Modal.Footer>
                      </Modal> */}
                     
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
