import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import "./SingleProductDetailsIslamInner.css";
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart } from "../../features/cartSlice";
import TopHeader from '../Headers/TopHeader/TopHeader';
import BottomHeader from '../Headers/BottomHeader/BottomHeader';

function SingleProductDetailsIslamInner() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [cartCount, setCartCount] = useState(1);

    const IslamicProductInnerItems = useSelector((state) => state.allCart.IslamicProductInnerItems);
    const item = IslamicProductInnerItems.find((p) => p.id === parseInt(id, 10));
    const [mainImage, setMainImage] = useState(item.img);

    const handleThumbnailClick = (imgSrc) => {
        setMainImage(imgSrc);
    };
    if (!item) {
        return <div>Product not found!</div>;
    }

    const handleIncrease = () => {
        setCartCount(cartCount + 1);
    };

    const handleDecrease = () => {
        if (cartCount > 0) {
            setCartCount(cartCount - 1);
        }
    };

    return (
        <div className='singleproduct'>
            <TopHeader />
            <BottomHeader />
            <Container>
                <Row>
                    <Col md={12} >
                        <Link to="" className='home_pro'>Home</Link> &nbsp; / &nbsp;
                        <span>Health & Beauty </span>

                    </Col>

                    <div className="main_product my-5">
                        <div className="heading_product my-5">
                            <h1>{item.title}</h1>
                            <div className="rating_product">
                                <ul>
                                    <li><i class="fa-solid fa-star"></i></li>
                                    <li><i class="fa-solid fa-star"></i></li>
                                    <li><i class="fa-solid fa-star"></i></li>
                                    <li><i class="fa-solid fa-star"></i></li>
                                    <li>0 Customer Reviews</li>
                                </ul>
                            </div>
                        </div>

                        <div className="main_images_producy">

                            <div className="image">
                                <div className="imgmain my-2" style={{ display: "flex", justifyContent: 'center' }}>
                                    <img src={mainImage} alt="" style={{ width: "auto", height: "300px" }} />
                                </div>
                                <ul style={{ display: "flex", gap: "10px" }}>
                                    <li style={{ display: "flex", gap: "10px" }}>
                                        {item.img2 && (
                                            <img
                                                src={item.img2}
                                                alt={`item ${item}`}
                                                style={{ width: "100px", height: "100px", cursor: 'pointer' }}
                                                onClick={() => handleThumbnailClick(item.img2)}
                                            />
                                        )}
                                        {item.img3 && (
                                            <img
                                                src={item.img3}
                                                alt={`item ${item}`}
                                                style={{ width: "100px", height: "100px", cursor: 'pointer' }}
                                                onClick={() => handleThumbnailClick(item.img3)}
                                            />
                                        )}
                                        {item.img4 && (
                                            <img
                                                src={item.img4}
                                                alt={`item ${item}`}
                                                style={{ width: "100px", height: "100px", cursor: 'pointer' }}
                                                onClick={() => handleThumbnailClick(item.img4)}
                                            />
                                        )}
                                        {item.img5 && (
                                            <img
                                                src={item.img5}
                                                alt={`item ${item}`}
                                                style={{ width: "100px", height: "100px", cursor: 'pointer' }}
                                                onClick={() => handleThumbnailClick(item.img5)}
                                            />
                                        )}
                                        {!item.img2 && !item.img3 && !item.img4 && !item.img5 && (
                                            <div>
                                                <p>Image not available</p>
                                                {/* Render your icon here */}
                                            </div>
                                        )}
                                    </li>
                                </ul>
                            </div>

                            <div className="contents ">
                                <div className="content_prices ">
                                     {/* <h2 className='price ' style={{ color: "red", }}>₨ {item.price}</h2> */}
                                    <p className='discription '>Green Roots Activated Charcoal Face Mask deeply cleanses, exfoliates and minimizes the pores from the skin. Makes skin clean and clear removes dead skin</p>
                                    <h2 className='availablitiy'>Availability : <span>Out of stock</span>  </h2>
                                    <div className="whishlist my-3">
                                        <i class="fa-regular fa-heart" style={{ fontSize: "16px" }}></i> <span>Add to wishlist</span>
                                    </div>

                                </div>
                                <div className="cotogory my-3">
                                    <h3 className='my-2'>{item.Format1}<span>{item.Format}</span></h3>
                                    <h3 className='my-2'>{item.Material1}<span>{item.Material}</span></h3>
                                    <h3 className='my-2'>{item.Color1}<span>{item.Color}</span></h3>
                                    <h3 className='my-2'>{item.ProductDimensions1}<span>{item.ProductDimensions}</span></h3>
                                    <h3 className='my-2'>{item.Brand1}<span>{item.Brand}</span></h3>
                                    <h3 className='my-2'>{item.SKU1}<span>{item.SKU}</span></h3>
                                    <h3 className='my-2'>{item.Category1}<span>{item.Category}</span></h3>
                                    {/* <h3 className='my-2'>Tags:<span style={{ fontSize: "12px" }}> activated charcoalcharcoal face maskclear skinoily skin careskin lighteningskin nourishing facewash</span></h3> */}
                                    <div>

                                        <div className="main_button_add d-flex gap-4 my-3">
                                            <div className="main_button_ d-flex gap-3 align-items-center">
                                                <button style={{ padding: '0px', background: 'none' }} onClick={handleIncrease}><i class="fa-solid fa-arrow-up" style={{ color: "#000" }}></i></button>
                                                <p> {cartCount}</p>
                                                <button onClick={handleDecrease} style={{ padding: '0px', background: 'none' }}  ><i class="fa-solid fa-arrow-down" style={{ color: "#000" }}></i></button>

                                            </div>
                                            <button onClick={() => dispatch(addToCart({ ...item, quantity: cartCount }))}>
                                                Add to Cart
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main_product main_product_1">

                  

                        <div className="main_reviews">
                            <h2 >Reviews</h2>
                            <p className='my-3'>There are no reviews yet.</p>
                            <h2>Be The First To Review “Activated Charcoal Face Mask”</h2>
                            <p className='my-3'>Your email address will not be published. Required fields are marked *</p>
                            <div className="rating_product">
                                <ul>
                                    <li> Your rating <span style={{ color: 'red' }}> * </span> </li>
                                    <li><i class="fa-solid fa-star"></i></li>
                                    <li><i class="fa-solid fa-star"></i></li>
                                    <li><i class="fa-solid fa-star"></i></li>
                                    <li><i class="fa-solid fa-star"></i></li>
                                </ul>
                            </div>
                            <div className="form my-3">
                                <Form.Label htmlFor="inputPassword5" style={{ display: 'block', marginBottom: '8px', fontSize: '15px' }}>Your review <span style={{ color: 'red' }}> * </span></Form.Label>
                                <textarea
                                    name=""
                                    id="inputPassword5"
                                    cols="30"
                                    rows="10"
                                    style={{
                                        width: '50%',
                                        padding: '8px',
                                        boxSizing: 'border-box',
                                        borderRadius: '4px',
                                        border: '1px solid #ccc',
                                        fontSize: '16px',
                                        marginBottom: '40px',
                                    }}
                                ></textarea>
                                <Form.Label htmlFor="inputPassword6" style={{textAlign:"left",display:"flex",justifyContent:"flex-start"}}>Name <span style={{ color: 'red' }}> * </span></Form.Label>
                                <Form.Control
                                    type="text"
                                    id="inputPassword6"
                                    aria-describedby="passwordHelpBlock2"
                                    style={{ width: "30%", outline: 'none', boxShadow: 'none' }}
                                />
                                <Form.Label htmlFor="inputPassword5">Email  <span style={{ color: 'red' }}> * </span></Form.Label>
                                <Form.Control
                                    type="text"
                                    id="inputPassword5"
                                    aria-describedby="passwordHelpBlock"
                                    style={{ width: "30%", outline: 'none', boxShadow: 'none' }}
                                />
                                <input type="checkbox" /> <span>Save my name, email, and website in this browser for the next time I comment.</span><br></br>
                                <button className='my-3'>Submit</button>
                            </div>
                        </div>

                    </div>

                </Row>
            </Container>


        </div>
    )
}

export default SingleProductDetailsIslamInner
