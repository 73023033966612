import React from 'react'
import "./Footer.css"
import { Link } from 'react-router-dom'
export default function Footer() {
    return (
        <div>
            <div className="container-fluid">
                <footer>
                    <div className="logo">
                        <img src="/assets/logo.png" alt="" style={{width:"auto",height:"100px"}} />
                      
                    </div>
                    <div className="pages">
                        <ul>
                            <li> <b> COMPANY LINKS</b></li>
                           
                            <li><Link to="/Shop">
                            Shop
                                </Link></li>
                            <li><Link to="/Contact">
                            Contact Us
                                </Link></li>
                         
                           
                        </ul>
                    </div>
                    <div className="pages">
                        <ul>
                            <li><b>CATEGORY</b></li>
                            <li><a href="">
                            Wall art 
                                </a></li>
                            <li><a href="">
                            Wall clock

                                </a></li>
                            <li><a href="">
                            Calendar
                                </a></li>
                            <li><a href="">
                            Islamic
                                </a></li>
                                <li><a href="">
                                Accessories
                                </a></li>
                           
                        </ul>
                    </div>
                    <div className="pages">
                        <ul>
                            <li> <b> CONTACT</b></li>
                            <li><a href="mailto:artstylodecor@gmail.com">
                            artstylodecor@gmail.com
                                </a></li>
                         
                            <li><a href="https://wa.me/923152226901" target='_blank'>
                            +92 315 2226901
                                </a></li>
                           
                           
                        </ul>
                    </div>
                </footer>
                <div className="footer_bottom">
                    <p>COPYRIGHT 2022 © ART STYLO. ALL RIGHTS RESERVED</p>
                </div>
            </div>
        </div>
    )
}
