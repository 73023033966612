import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import {
  FaWhatsapp,
  FaCheck,
  FaTimes,
  FaPrint,
  FaMinus,
  FaPlus,
} from "react-icons/fa";
import user from "../../assets/round.avif";
import "./Modal.css";
import Footer from "../Footer/Footer";
import TopHeader from "../Headers/TopHeader/TopHeader";
import BottomHeader from "../Headers/BottomHeader/BottomHeader";

const Modal = () => {
  return (
    <>
     <TopHeader />
     <BottomHeader />
     <div className="container my-5 modal_content">
      <div className="modal-content">
        <div className="modal_header">
          <div className="order_no"> <b>Order ID #1234</b></div>
          <div className="btt">
            <p><b>Mark Order As</b></p>
            <div className="but d-flex gap-2">
              <button className="btn-cancel" style={{
                background:"#EB534E",
                color:"#fff"
              }}>
                <FaTimes /> Cancelled
              </button>
              <button className="btn-accept" style={{
                background:"#22CA6B",
                color:"#fff"
              }}>
                <FaCheck /> Accept
              </button>
            </div>
            <div
              className="btttn d-flex"
              style={{
                justifyContent: "end",
                paddingTop: "10px",
              }}
            >
              <button className="btn-whatsapp" 
              style={{
                background:"#89C14B",
                color:"#fff"
              }}>
                Send WhatsApp to Customer <FaWhatsapp />
              </button>
            </div>
          </div>
        </div>
        <div className="profile_main my-5 gap-5">
          <div className="profile w-75">
            <div className="profile_img">
              <div className="user_img">
                <img src={user} alt="User" 
                // style={
                //   {
                //     width:"auto",
                //     height:"100px",
                //     borderRadius:"100%"
                //   }
                // }
                />
              </div>
              <p style={{ textAlign: "center" }}>Ubaid Raza</p>
              <div className="tab">
                <h5>Customer Details</h5>
                <Table bordered variant="dark">
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>Muhammad Ubaid</td>
                    </tr>
                    <tr>
                      <td>Phone Number</td>
                      <td>03448182388</td>
                    </tr>
                    <tr>
                      <td>Delivery Address</td>
                      <td>Address no 15</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="order_detail w-75">
            <div className="buttons d-flex gap-2 align-items-center">
              <Button
                variant="primary"
                className="d-flex gap-2 align-items-center btn-print"
                size="sm"
              >
                <FaPrint /> Print
              </Button>{" "}
              <Button variant="primary" size="sm">
                Change Status
              </Button>{" "}
              <Button variant="primary" size="sm">
                Change Branch
              </Button>{" "}
            </div>
            <div className="table my-3">
              <h4>Order Details</h4>
              <Table bordered variant="dark">
                <thead>
                  <tr>
                    <th>Order #</th>
                    <th>Customer Ref #</th>
                    <th>Order Status</th>
                    <th>Order Date/Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>11d - 1379</td>
                    <td>nfavsh</td>
                    <td>
                      <Button variant="primary" size="sm" className="success">
                        accepted
                      </Button>{" "}
                    </td>
                    <td>15-7-24</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="branch">
              <div className="branch_details">
                <p>
                  <b>Branch Name</b> <span>Art Stylo</span>
                </p>
                <p>
                  <b>Payment Type</b> <span>Cash</span>
                </p>
                <p>
                  <b>Delivery Date</b> <span>Jul,08,2024</span>
                </p>
                <p>
                  <b>Delivery ASAP</b> <span>Yes</span>
                </p>
                <p>
                  <b>Order Platform</b> <span>Art Stylo</span>
                </p>
              </div>
              <div className="thai">
                <div className="thai_rice">
                  <p>
                    <b>Wall Art</b>{" "}
                  </p>
                  <div className="per d-flex align-items-baseline gap-5">
                    <p>
                      1 Wall Art <br />
                      <span style={{ fontSize: "11px" }}>Rs. 500</span>
                    </p>
                    <p>Rs. 500</p>
                  </div>
                  <div className="thai_rice">
                    <p>
                      <b>Leaves Wall Art</b>{" "}
                    </p>
                    <div className="per d-flex align-items-baseline gap-5">
                      <p>
                        1 Leaves Wall Art <br />
                        <span style={{ fontSize: "11px" }}>Rs. 900</span>
                      </p>
                      <p>Rs. 900</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex">
            <div className="div d-flex align-items-baseline gap-2 w-100 flex-column">
              
              {/* <div
                className="per d-flex align-items-baseline gap-2"
                style={{
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  className="pera d-flex align-items-baseline gap-2"
                  style={{
                    backgroundColor: "#8CC14B",
                    color: "#fff",
                    padding: "10px 10px",
                    
                  }}
                >
                  <div
                    className="icon_minus"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <button
                      style={{
                        border: "1px solid #fff",
                        borderRadius: "100%",
                      }}
                    >
                      <i class="fa-solid fa-minus"   style={{
                          padding: "5px 10px",
                          color: "#fff",
                        }}></i>
                     
                 
                    </button>
                  
                  </div>
             
                  <button
                      style={{
                        border: "1px solid #fff",
                        borderRadius: "100%",
                      }}
                    >
                      <i class="fa-solid fa-plus"></i>
                      
                    </button>
                </div>
              </div>
              <div
                className="pera d-flex align-items-baseline gap-2"
                style={{
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  className="pera d-flex align-items-baseline gap-2"
                  style={{
                    backgroundColor: "#8CC14B",
                    color: "#fff",
                    padding: "10px 10px",
                  }}
                >
                  <div
                    className="icon_minus"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <button
                      style={{
                        border: "1px solid #fff",
                        borderRadius: "100%",
                      }}
                    >
                      <FaMinus
                        style={{
                          padding: "5px 10px",
                          color: "#fff",
                        }}
                      />
                    </button>
                    <button
                      style={{
                        border: "1px solid #fff",
                        borderRadius: "100%",
                      }}
                    >
                      <FaPlus
                        style={{
                          padding: "5px 10px",
                          color: "#fff",
                        }}
                      />
                    </button>
                  </div>
                  <p>
                    {1} <br />
                    <span style={{ fontSize: "11px" }}>Wall Art</span>
                  </p>
                  <p>
                    1 <br />
                    <span style={{ fontSize: "11px" }}>Rs. 500</span>
                  </p>
                  <p>
                    Rs. 500 <br />
                    <span style={{ fontSize: "11px" }}>Wall Art</span>
                  </p>
                </div>
              </div> */}
            </div>
            <div
              className="d-flex align-items-baseline"
              style={{
                flexDirection: "column",
                width: "100%",
                // backgroundColor: "#ddd",
                padding: "10px 10px",
              }}
            >
              <div className="d-flex align-items-baseline gap-2 pera1">
                <div className="s1">
                  <p>
                    <b>Sub Total</b> <span>Rs. 900</span>
                  </p>
                </div>
                <div className="s2">
                  <p>
                    <b>Delivery Charges</b> <span>Free</span>
                  </p>
                </div>
                <div className="s3">
                  <p>
                    <b>Discount</b> <span>Rs. 0</span>
                  </p>
                </div>
                <div className="s4">
                  <p>
                    <b>Total</b> <span>Rs. 1400</span>
                  </p>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <Footer />
    </>
  );
};

export default Modal;
