import React from 'react'
import "./ProductSlideOne.css"
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from "../../../features/cartSlice";
export default function ProductSlideOne() {
    const IslamicProductItems = useSelector((state) => state.allCart.IslamicProductItems);
    const healthProductMainItems = useSelector((state) => state.allCart.healthProductMainItems);
    const WallArtItems = useSelector((state) => state.allCart.WallArtItems);
    const IslamicProductInnerItems = useSelector((state) => state.allCart.IslamicProductInnerItems);
    const WallArtInnerItems = useSelector((state) => state.allCart.WallArtInnerItems);
    const dispatch = useDispatch();
    const CustomPrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            >
                <span className="custom-prev-arrow"><i class="fa-solid fa-arrow-left"></i></span>
            </div>
        );
    };

    const CustomNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            >
                <span className="custom-next-arrow"><i class="fa-solid fa-arrow-right"></i></span>
            </div>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1324,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1124,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='ProductSlideOne'>
            <h1>
                Our Products
            </h1>
            <Slider {...settings}>
            {healthProductMainItems.map((item) => (
                    <div className="col-3">
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={item.imgpx} />
                        <Card.Body>
                          <Card.Title className='mt-4'>{item.title_one}</Card.Title>
                          <Card.Text>
                            {item.title}
                          </Card.Text>
                          {/* <Card.Text>
                            <del>{item.pricedel}</del> <span>₨ {item.price}</span>
                          </Card.Text> */}

                          <Card.Text>
                            <div class="contain">
                              <div class="animated-bg">
                                <div className="links">
                                  <Link to="#" onClick={() => dispatch(addToCart(item))}>
                                    <i className="fa-solid fa-cart-shopping" ></i>
                                  </Link>
                                  <Link to={`/SingleProductDetailstow/${item.id}`} >
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Card.Text>

                          <Card.Text>
                            <Link to="#">
                              <i class="fa-regular fa-heart"></i>
                            </Link>
                          </Card.Text>

                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                  {IslamicProductItems.map((item) => (
                    <div className="col-3">
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={item.imgpx } />
                        <Card.Body>
                          <Card.Title className='mt-4'>{item.title_one}</Card.Title>
                          <Card.Text>
                            {item.title}
                          </Card.Text>
                          {/* <Card.Text>
                            <del>{item.pricedel}</del> <span>₨ {item.price}</span>
                          </Card.Text> */}

                          <Card.Text>
                            <div class="contain">
                              <div class="animated-bg">
                                <div className="links">
                                  <Link to="#" onClick={() => dispatch(addToCart(item))}>
                                    <i className="fa-solid fa-cart-shopping" ></i>
                                  </Link>
                                  <Link to={`/SingleProductDetailsIslam/${item.id}`} >
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Card.Text>

                          <Card.Text>
                            <Link to="#">
                              <i class="fa-regular fa-heart"></i>
                            </Link>
                          </Card.Text>

                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                  {WallArtItems.map((item) => (
                    <div className="col-3">
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={item.imgpx} />
                        <Card.Body>
                          <Card.Title className='mt-4'>{item.title_one}</Card.Title>
                          <Card.Text>
                            {item.title}
                          </Card.Text>
                          {/* <Card.Text>
                            <del>{item.pricedel}</del> <span>₨ {item.price}</span>
                          </Card.Text> */}

                          <Card.Text>
                            <div class="contain">
                              <div class="animated-bg">
                                <div className="links">
                                  <Link to="#" onClick={() => dispatch(addToCart(item))}>
                                    <i className="fa-solid fa-cart-shopping" ></i>
                                  </Link>
                                  <Link to={`/SingleProductDetailsIWallArt/${item.id}`} >
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Card.Text>

                          <Card.Text>
                            <Link to="#">
                              <i class="fa-regular fa-heart"></i>
                            </Link>
                          </Card.Text>

                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                     {WallArtInnerItems.map((item) => (
                    <div className="col-3">
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={item.img} />
                        <Card.Body>
                          <Card.Title className='mt-4'>{item.title_one}</Card.Title>
                          <Card.Text>
                            {item.title}
                          </Card.Text>
                          {/* <Card.Text>
                            <del>{item.pricedel}</del> <span>₨ {item.price}</span>
                          </Card.Text> */}

                          <Card.Text>
                            <div class="contain">
                              <div class="animated-bg">
                                <div className="links">
                                  <Link to="#" onClick={() => dispatch(addToCart(item))}>
                                    <i className="fa-solid fa-cart-shopping" ></i>
                                  </Link>
                                  <Link to={`/SingleProductDetailsIWallArtInner/${item.id}`} >
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Card.Text>

                          <Card.Text>
                            <Link to="#">
                              <i class="fa-regular fa-heart"></i>
                            </Link>
                          </Card.Text>

                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                    {IslamicProductInnerItems.map((item) => (
                    <div className="col-3">
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={item.img} />
                        <Card.Body>
                          <Card.Title className='mt-4'>{item.title_one}</Card.Title>
                          <Card.Text>
                            {item.title}
                          </Card.Text>
                          {/* <Card.Text>
                            <del>{item.pricedel}</del> <span>₨ {item.price}</span>
                          </Card.Text> */}

                          <Card.Text>
                            <div class="contain">
                              <div class="animated-bg">
                                <div className="links">
                                  <Link to="#" onClick={() => dispatch(addToCart(item))}>
                                    <i className="fa-solid fa-cart-shopping" ></i>
                                  </Link>
                                  <Link to={`/SingleProductDetailsIslamInner/${item.id}`} >
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Card.Text>

                          <Card.Text>
                            <Link to="#">
                              <i class="fa-regular fa-heart"></i>
                            </Link>
                          </Card.Text>

                        </Card.Body>
                      </Card>
                    </div>
                  ))}
            </Slider>
        </div>
    )
}
