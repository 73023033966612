import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import TopHeader from '../Headers/TopHeader/TopHeader';
import BottomHeader from '../Headers/BottomHeader/BottomHeader';
import Footer from '../Footer/Footer';
import axios from 'axios';
import "./Customer_Profile.css"

export default function Customer_Profile() {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get('http://localhost:8081/Customer_Profile')
            .then(response => {
                console.log(response.data); // Log the response data
                // Format dates in response to DD-MM-YYYY
                const formattedCustomers = response.data.map(customer => ({
                    ...customer,
                    DOR: formatDate(customer.DOR) // Assuming DOR is a string date
                }));
                setCustomers(formattedCustomers);
                setLoading(false);
            })
            .catch(error => {
                console.log('Error fetching customer profiles:', error);
                setLoading(false);
            });
    }, []);

    // Function to format date to DD-MM-YYY Y
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    if (loading) {
        return (
            <div>
                <TopHeader />
                <BottomHeader />
                <div className="container">
                    <div className="Orders myp-5">
                        <h1 align="center" className='my-5'>Customer Profiles</h1>
                        <p>Loading...</p>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

    return (
        <div>
            <TopHeader />
            <BottomHeader />
            <div className="container">
                <div className="Orders myp-5">
                    <h1 align="center" className='my-5'>Customer Profiles</h1>
                    {customers.length === 0 ? (
                        <p>No customer profiles available</p>
                    ) : (
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Customer Name</th>
                                    <th>Delivery Address</th>
                                    <th>Country</th>
                                    <th>Province</th>
                                    <th>City</th>
                                    <th>Phone Number</th>
                                    <th>Email Address</th>
                                    <th>DOR</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customers.map(customer => (
                                    <tr key={customer.id}>
                                        <td>{customer.id}</td>
                                        <td>{customer.Customer_Name}</td>
                                        <td>{customer.Delivery_Address}</td>
                                        <td>{customer.Country}</td>
                                        <td>{customer.Province}</td>
                                        <td>{customer.City}</td>
                                        <td>{customer.Phone_Number}</td>
                                        <td>{customer.Email_Address}</td>
                                        <td>{customer.DOR}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}
