import React from 'react'
import "./ProductTabs.css"
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from "../../../features/cartSlice";
import 'react-toastify/dist/ReactToastify.css';
export default function ProductTabs() {
  const healthProductMainItems = useSelector((state) => state.allCart.healthProductMainItems);
  const WallArtItems = useSelector((state) => state.allCart.WallArtItems);
  const IslamicProductItems = useSelector((state) => state.allCart.IslamicProductItems);
  const IslamicProductInnerItems = useSelector((state) => state.allCart.IslamicProductInnerItems);
  const WallArtInnerItems = useSelector((state) => state.allCart.WallArtInnerItems);
  

  const dispatch = useDispatch();
  return (
    <div className='ProductTabs my-5'>
      <div className="container-fluid">
        <h1>NEW ARRIVAL</h1>
        <div class="body">
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                type="button" role="tab" aria-controls="pills-home" aria-selected="true">All products</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Wall art</button>
            </li>
            {/* <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact"
                type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Wall clock
              </button>
            </li> */}
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-rww-tab" data-bs-toggle="pill" data-bs-target="#pills-rww"
                type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Calendar</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-rw-tabislamic" data-bs-toggle="pill" data-bs-target="#pills-rwislamic" type="button"
                role="tab" aria-controls="pills-contact" aria-selected="false">Islamic</button>
            </li>
            {/* <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-rw-tabbag" data-bs-toggle="pill" data-bs-target="#pills-rwbags" type="button"
                role="tab" aria-controls="pills-contact" aria-selected="false">Accessories
              </button>
            </li> */}

          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active py-5" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab"
              tabindex="0">
              <div className="conatiner-fluid">

                <div className="row">
                  {healthProductMainItems.map((item) => (
                    <div className="col-3">
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={item.img} />
                        <Card.Body>
                          <Card.Title className='mt-4'>{item.title_one}</Card.Title>
                          <Card.Text>
                            {item.title}
                          </Card.Text>
                          {/* <Card.Text>
                            <del>{item.pricedel}</del> <span>₨ {item.price}</span>
                          </Card.Text> */}

                          <Card.Text>
                            <div class="contain">
                              <div class="animated-bg">
                                <div className="links">
                                  <Link to="#" onClick={() => dispatch(addToCart(item))}>
                                    <i className="fa-solid fa-cart-shopping" ></i>
                                  </Link>
                                  <Link to={`/SingleProductDetailstow/${item.id}`} >
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Card.Text>

                          <Card.Text>
                            <Link to="#">
                              <i class="fa-regular fa-heart"></i>
                            </Link>
                          </Card.Text>

                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                  {IslamicProductItems.map((item) => (
                    <div className="col-3">
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={item.img} />
                        <Card.Body>
                          <Card.Title className='mt-4'>{item.title_one}</Card.Title>
                          <Card.Text>
                            {item.title}
                          </Card.Text>
                          {/* <Card.Text>
                            <del>{item.pricedel}</del> <span>₨ {item.price}</span>
                          </Card.Text> */}

                          <Card.Text>
                            <div class="contain">
                              <div class="animated-bg">
                                <div className="links">
                                  <Link to="#" onClick={() => dispatch(addToCart(item))}>
                                    <i className="fa-solid fa-cart-shopping" ></i>
                                  </Link>
                                  <Link to={`/SingleProductDetailsIslam/${item.id}`} >
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Card.Text>

                          <Card.Text>
                            <Link to="#">
                              <i class="fa-regular fa-heart"></i>
                            </Link>
                          </Card.Text>

                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                  {WallArtItems.map((item) => (
                    <div className="col-3">
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={item.img} />
                        <Card.Body>
                          <Card.Title className='mt-4'>{item.title_one}</Card.Title>
                          <Card.Text>
                            {item.title}
                          </Card.Text>
                          {/* <Card.Text>
                            <del>{item.pricedel}</del> <span>₨ {item.price}</span>
                          </Card.Text> */}

                          <Card.Text>
                            <div class="contain">
                              <div class="animated-bg">
                                <div className="links">
                                  <Link to="#" onClick={() => dispatch(addToCart(item))}>
                                    <i className="fa-solid fa-cart-shopping" ></i>
                                  </Link>
                                  <Link to={`/SingleProductDetailsIWallArt/${item.id}`} >
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Card.Text>

                          <Card.Text>
                            <Link to="#">
                              <i class="fa-regular fa-heart"></i>
                            </Link>
                          </Card.Text>

                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </div>


              </div>
            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"
              tabindex="1">
              <div className="conatiner-fluid">

                <div className="row">
                  {WallArtItems.map((item) => (
                    <div className="col-3">
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={item.img} />
                        <Card.Body>
                          <Card.Title className='mt-4'>{item.title_one}</Card.Title>
                          <Card.Text>
                            {item.title}
                          </Card.Text>
                          {/* <Card.Text>
                            <del>{item.pricedel}</del> <span>₨ {item.price}</span>
                          </Card.Text> */}

                          <Card.Text>
                            <div class="contain">
                              <div class="animated-bg">
                                <div className="links">
                                  <Link to="#" onClick={() => dispatch(addToCart(item))}>
                                    <i className="fa-solid fa-cart-shopping" ></i>
                                  </Link>
                                  <Link to={`/SingleProductDetailsIWallArt/${item.id}`} >
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Card.Text>

                          <Card.Text>
                            <Link to="#">
                              <i class="fa-regular fa-heart"></i>
                            </Link>
                          </Card.Text>

                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                    {WallArtInnerItems.map((item) => (
                    <div className="col-3">
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={item.img} />
                        <Card.Body>
                          <Card.Title className='mt-4'>{item.title_one}</Card.Title>
                          <Card.Text>
                            {item.title}
                          </Card.Text>
                          {/* <Card.Text>
                            <del>{item.pricedel}</del> <span>₨ {item.price}</span>
                          </Card.Text> */}

                          <Card.Text>
                            <div class="contain">
                              <div class="animated-bg">
                                <div className="links">
                                  <Link to="#" onClick={() => dispatch(addToCart(item))}>
                                    <i className="fa-solid fa-cart-shopping" ></i>
                                  </Link>
                                  <Link to={`/SingleProductDetailsIWallArtInner/${item.id}`} >
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Card.Text>

                          <Card.Text>
                            <Link to="#">
                              <i class="fa-regular fa-heart"></i>
                            </Link>
                          </Card.Text>

                        </Card.Body>
                      </Card>
                    </div>
                  ))}

                </div>


              </div>
            </div>
            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab"
              tabindex="2">

            </div>
            <div class="tab-pane fade" id="pills-rww" role="tabpanel" aria-labelledby="pills-rww-tab" tabindex="3">
              <div className="conatiner-fluid">

                <div className="row">
                  {healthProductMainItems.map((item) => (
                    <div className="col-3">
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={item.img} />
                        <Card.Body>
                          <Card.Title className='mt-4'>{item.title_one}</Card.Title>
                          <Card.Text>
                            {item.title}
                          </Card.Text>
                            {/* <Card.Text>
                              <del>{item.pricedel}</del> <span>₨ {item.price}</span>
                            </Card.Text> */}

                          <Card.Text>
                            <div class="contain">
                              <div class="animated-bg">
                                <div className="links">
                                  <Link to="#" onClick={() => dispatch(addToCart(item))}>
                                    <i className="fa-solid fa-cart-shopping" ></i>
                                  </Link>
                                  <Link to={`/SingleProductDetailstow/${item.id}`} >
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Card.Text>

                          <Card.Text>
                            <Link to="#">
                              <i class="fa-regular fa-heart"></i>
                            </Link>
                          </Card.Text>

                        </Card.Body>
                      </Card>
                    </div>
                  ))}

                </div>


              </div>
            </div>
            <div class="tab-pane fade" id="pills-rwislamic" role="tabpanel" aria-labelledby="pills-rw-tabislamic" tabindex="3">
              <div className="conatiner-fluid">

                <div className="row">
                  {IslamicProductItems.map((item) => (
                    <div className="col-3">
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={item.img} />
                        <Card.Body>
                          <Card.Title className='mt-4'>{item.title_one}</Card.Title>
                          <Card.Text>
                            {item.title}
                          </Card.Text>
                          {/* <Card.Text>
                            <del>{item.pricedel}</del> <span>₨ {item.price}</span>
                          </Card.Text> */}

                          <Card.Text>
                            <div class="contain">
                              <div class="animated-bg">
                                <div className="links">
                                  <Link to="#" onClick={() => dispatch(addToCart(item))}>
                                    <i className="fa-solid fa-cart-shopping" ></i>
                                  </Link>
                                  <Link to={`/SingleProductDetailsIslam/${item.id}`} >
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Card.Text>

                          <Card.Text>
                            <Link to="#">
                              <i class="fa-regular fa-heart"></i>
                            </Link>
                          </Card.Text>

                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                  {IslamicProductInnerItems.map((item) => (
                    <div className="col-3">
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={item.img} />
                        <Card.Body>
                          <Card.Title className='mt-4'>{item.title_one}</Card.Title>
                          <Card.Text>
                            {item.title}
                          </Card.Text>
                          {/* <Card.Text>
                            <del>{item.pricedel}</del> <span>₨ {item.price}</span>
                          </Card.Text> */}

                          <Card.Text>
                            <div class="contain">
                              <div class="animated-bg">
                                <div className="links">
                                  <Link to="#" onClick={() => dispatch(addToCart(item))}>
                                    <i className="fa-solid fa-cart-shopping" ></i>
                                  </Link>
                                  <Link to={`/SingleProductDetailsIslamInner/${item.id}`} >
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Card.Text>

                          <Card.Text>
                            <Link to="#">
                              <i class="fa-regular fa-heart"></i>
                            </Link>
                          </Card.Text>

                        </Card.Body>
                      </Card>
                    </div>
                  ))}

                </div>


              </div>
            </div>
            <div class="tab-pane fade" id="pills-rwbags" role="tabpanel" aria-labelledby="pills-rw-tabbag" tabindex="3">

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
