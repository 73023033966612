import React from 'react'
// import Product from '../Products/Product'
import TopHeader from '../../Headers/TopHeader/TopHeader'
import BottomHeader from '../../Headers/BottomHeader/BottomHeader'
import Footer from '../../Footer/Footer'
import ProductTabs from '../../HomeComponents/ProductTabs/ProductTabs'
export default function Shop() {
    return (
        <div>
          <TopHeader/>
          <BottomHeader/>
          <ProductTabs/>
          <Footer/>
        </div>
    )
}
