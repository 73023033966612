import React from 'react'
import './ContactForm.css'

function ContactForm() {
    return (
        <div className='main-form-page'>
            <div className='contact-us'>
                <h1>Contact Us</h1>
                <div className='Heading-link'>
                    <div className='Home'>
                        <span><a href="">Home</a></span>
                    </div>
                    <div className='Lessthan'>
                        <span>{">"}</span>
                    </div>
                    <div className='second-contactus'>
                        <span><p>Contact Us</p></span>
                    </div>
                </div>
            </div>
            <div className='form-page'>
                <div className='Contact-form'>
                    <div className='main-form'>
                        <div className='questions-heading'>
                            <h2>Contact us for any questions</h2>
                        </div>
                        <div className='two-names'>
                            <div className='First-name'>
                                <label>First name*</label>
                                <br />
                                <input type="text" />
                              
                            </div>
                            {/* &nbsp; */}
                            <div className='Last-name'>
                                <label>Last name*</label>
                                <br />
                                <input type="text" />
                            </div>
                        </div>
                        <div className='Email-box'>
                            <label>Email*</label>
                            <br />
                            <input type="text" />
                        </div>
                        <div className='Phone-number'>
                            <label>Phone Number*</label>
                            <br />
                            <input type="text" />
                        </div>
                        <div className='Help-box'>
                            <label>How can we help?</label>
                            <br />
                            <textarea name="" id=""></textarea>
                        </div>
                        <div className='Message-button'>
                            <button>Send Message</button>
                        </div>
                    </div>
                </div>
                <div className='dispaly-contact'>
                    <div className='contact-info'>
                        <h1>Contact info</h1>
                    </div>
                    <div className='call-to'>
                        <h2>Call to Us:</h2>
                        <p>We’re available from 10 am – 10 pm
                            <br />  7 days a week.</p>
                    </div>
                    <div className='Customer-Service'>
                        <h4>Customer Service</h4>
                        <div className='two-numbers'>
                            <div className='first-number'>
                            <a href="https://wa.me/923152226901" target='_blank'>
                            +92 315 2226901
                                </a>
                            </div>
                           
                        </div>
                    </div>
                   
                    <div className='Email-to-Us'>
                        <h4>Email to Us</h4>
                        <a href="mailto:artstylodecor@gmail.com">
                            artstylodecor@gmail.com
                                </a>
                    </div>
                   
                </div>
            </div>
        </div>
    )
}

export default ContactForm
