import React, { useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./Components/HomeComponents/Home/Home";
import Preloader from './Components/Preloader/Preloader';
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import SingleProductDetailstow from "./Components/SingleProductDetailstwo/SingleProductDetailstwo"
import CartPage from './Components/Addtocart/cartPage';
import CheckoutForm from './Components/Checkout/CheckoutForm';
import SingleProductDetailsIslam from './Components/SingleProductDetailsIslam/SingleProductDetailsIslam';
import SingleProductDetailsIWallArt from './Components/SingleProductDetailsIWallArt/SingleProductDetailsIWallArt';
import SingleProductDetailsIslamInner from './Components/SingleProductDetailsIslamInner/SingleProductDetailsIslamInner';
import SingleProductDetailsIWallArtInner from './Components/SingleProductDetailsIWallArtInner/SingleProductDetailsIWallArtInner';
import Shop from './Components/ShopComponents/Shop/Shop';
import Contact from './Components/ContactComponents/ContactComponents/Contact/Contact';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Components/Forms/Login/Login';
import SignUp from './Components/Forms/SignUp/SignUp';
import NotFound from './Components/404NotFount/404NotFount';
import Orders from './Components/Orders/Orders';
import Customer_Profile from './Components/Customer_Profile/Customer_Profile';
import Modal from './Components/Orders/Modal';
function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the timeout to match your loading duration

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
       <ToastContainer
                dangerouslyUseHTMLString={true} // Enable HTML rendering
            />
      {loading ? (
        <Preloader />
      ) : (
        <BrowserRouter>
          <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/CartPage" element={<CartPage />} />
          <Route path="/SingleProductDetailstow/:id" element={<SingleProductDetailstow/>} />
          <Route path="/CheckoutForm" element={<CheckoutForm />} />
          
          <Route path="/SingleProductDetailsIslam/:id" element={<SingleProductDetailsIslam/>} />
          <Route path="/SingleProductDetailsIWallArt/:id" element={<SingleProductDetailsIWallArt/>} />
          <Route path="/SingleProductDetailsIslamInner/:id" element={<SingleProductDetailsIslamInner/>} />
          <Route path="/SingleProductDetailsIWallArtInner/:id" element={<SingleProductDetailsIWallArtInner/>} />
          <Route path="/Shop" element={<Shop/>} />
          <Route path="/Contact" element={<Contact/>} />
          <Route path="/Login" element={<Login/>} />
          <Route path="/SignUp" element={<SignUp/>} />
          <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 */}
          <Route path="/Orders" element={<Orders />} />
          <Route path="/Customer_Profile" element={<Customer_Profile />} />
          <Route path="/Modal" element={<Modal />} />
          
          </Routes>
          </BrowserRouter>
          
      )}
    </div>
  );
}

export default App;
