import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import "./cartPage.css";
import {
  getCartTotal,
  removeItem,
  decreaseItemQuantity,
  increaseItemQuantity,
} from "../../features/cartSlice";
import { Link } from 'react-router-dom';
import TopHeader from "../Headers/TopHeader/TopHeader";
import BottomHeader from "../Headers/BottomHeader/BottomHeader";

const CartPage = () => {
  const { cart, totalQuantity, totalPrice } = useSelector((state) => state.allCart);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCartTotal());
  }, [dispatch, cart]);

  
  return (
    <div>
      <TopHeader />
      <BottomHeader />
      <section className="h-100 gradient-custom cartpage_page">
  <div className="container-fluid py-5 cart_page_con">
    <h3 className="text-center mb-4">Shopping Cart</h3>
    <div className="row d-flex justify-content-center my-4">
      <div className="col-md-8 col_cart_1">
        <div className="card mb-4">
          <div className="card-header py-3">
            <table className="table">
              <thead>
                <tr>
                  <th style={{ border: 'none' }}>Image</th>
                  <th style={{ border: 'none' }}>SKU</th>
                  <th style={{ border: 'none' }}>Product Name</th>
                  <th className="quantity" style={{ border: 'none', width: '193px !important' }}>Quantity</th>
                  <th className="price" style={{ border: 'none', width: '196px !important' }}>Price</th>
                  <th style={{ border: 'none' }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {cart?.map((data) => (
                  <tr key={data.id}>
                    <td>
                      <img style={{ width: 'auto', height: '40px' }} src={data.img} alt={data.title} />
                    </td>
                    <td>{data.SKU}</td>
                    <td>{data.title}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <button className="btn btn-primary px-2 me-2" onClick={() => dispatch(decreaseItemQuantity(data.id))}>
                          <i className="fas fa-minus" style={{ fontSize: '10px' }}></i>
                        </button>
                        <input
                          min="0"
                          name="quantity"
                          value={data.quantity}
                          type="number"
                          className="form-control"
                          style={{ width: '60px', fontSize: '12px' }}
                          readOnly
                        />
                        <button className="btn btn-primary px-2 ms-2" onClick={() => dispatch(increaseItemQuantity(data.id))}>
                          <i className="fas fa-plus" style={{ fontSize: '10px' }}></i>
                        </button>
                      </div>
                    </td>
                    <td>
                      {/* {data.price} */} 0
                      </td>
                    <td>
                      <button className="btn btn-danger btn-sm" onClick={() => dispatch(removeItem(data.id))}>
                        <i className="fas fa-trash" style={{ fontSize: '10px' }}></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-md-3 col_cart_2">
        <div className="card mb-4">
          <div className="card-header py-3">
            <h5 className="mb-0" style={{ fontSize: '14px' }}>Summary</h5>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush">
              <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                <span style={{ fontSize: '12px' }}>Total Quantity</span>
                <span style={{ fontSize: '12px' }}>{totalQuantity}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                <div>
                  <strong style={{ fontSize: '12px' }}>Total Amount</strong>
                </div>
                <span>
                  <strong style={{ fontSize: '12px' }}>
                    
                    {/* {totalPrice} */} 0
                    
                    </strong>
                </span>
              </li>
            </ul>
            <Link to="/CheckoutForm">
              <button type="button" className="btn btn-primary btn-lg btn-block w-100" style={{ fontSize: '14px' }}>
                Go to Checkout
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



    </div>
  );
};

export default CartPage;
